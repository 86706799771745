import React from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { withAuthorProvider } from './AuthorContext';
import InfoSection from './InfoSection';
import FeaturedInSection from './FeaturedInSection';
import { useAuthorData } from './hooks';

import { ContainedLayout } from 'components/Layout';

const Content = styled.article`
  ${({ theme: { mq } }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    padding: 60px 0 180px;

    ${mq.md} {
      row-gap: 32px;
      padding-bottom: 120px;
    }
  `}
`;

const Author: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps } = useAuthorData();

  return (
    <ContainedLayout {...layoutProps}>
      <Content>
        <InfoSection />
        <FeaturedInSection />
      </Content>
    </ContainedLayout>
  );
};

export const authorFragment = graphql`
  fragment AuthorData on PrismicAuthor {
    ...AuthorInfoData

    lang
    alternate_languages {
      document {
        ... on PrismicAuthor {
          lang
          url
        }
      }
    }
  }
`;

export default withAuthorProvider(Author);
