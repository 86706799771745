import { useAuthor } from './AuthorContext';

import { useLayoutProps, TAlternateLanguages } from 'components/Layout';

export const useAuthorData = () => {
  const { lang, alternate_languages } = useAuthor().author;

  return {
    layoutProps: {
      ...useLayoutProps({
        lang,
        alternate_languages: alternate_languages as TAlternateLanguages,
        isDark: true,
      }),
    },
  };
};
