import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import { useFeaturedInData } from './hooks';

import BlogArticleThumbnails from 'components/BlogArticleThumbnails';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 48px;

    ${mq.md} {
      row-gap: 32px;
    }
  `}
`;

const Title = styled.h2`
  ${({ theme: { mq, tp } }) => css`
    ${tp.useFont(fonts => fonts.Inter.semiBold)}
    font-size: 42px;
    line-height: 57px;
    margin: 0;

    ${mq.md} {
      font-size: 22px;
    }
  `}
`;

const FeaturedInSection: React.FC = () => {
  const featuredInArticles = useFeaturedInData();

  return (
    <Container>
      <Title>Featured in</Title>
      <BlogArticleThumbnails thumbnailsData={featuredInArticles} />
    </Container>
  );
};

export const featuredInSectionFragment = graphql`
  fragment AuthorsFeaturedInData on PrismicBlogArticleConnection {
    group(field: data___author___uid) {
      fieldValue
      nodes {
        ...BlogArticleThumbnailData
      }
    }
  }
`;

export default FeaturedInSection;
