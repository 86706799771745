import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Author from './Author';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.AuthorQuery>('author');

export const query = graphql`
  query Author($id: String) {
    author: prismicAuthor(id: { eq: $id }) {
      _previewable
      ...SEOData
      ...AuthorData
    }
    featuredIn: allPrismicBlogArticle {
      ...AuthorsFeaturedInData
    }
  }
`;

export type AllAuthorsQuery = Queries.AllAuthorsQuery;

export default withPrismicPreview(Author);
