import { useAuthor } from '../AuthorContext';

import { Normalize } from 'utils/types';

export const useFeaturedInData = () => {
  const {
    author: { uid },
    featuredIn: { group: authors },
  } = useAuthor();

  return (authors.find(({ fieldValue }) => uid === fieldValue)?.nodes ??
    []) as Normalize<Queries.BlogArticleThumbnailDataFragment>[];
};
