import { useAuthor } from '../AuthorContext';

export const useInfoData = () => {
  const {
    uid,
    data: { name, occupation, description, facebook, instagram, linkedin, twitter },
  } = useAuthor().author;

  return {
    name: name ?? uid,
    occupation,
    description: description?.richText ?? null,
    facebook: facebook?.url ?? null,
    instagram: instagram?.url ?? null,
    linkedIn: linkedin?.url ?? null,
    twitter: twitter?.url ?? null,
  };
};
